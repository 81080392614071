img {
  max-width: 100%;
  height: auto;
}

button {
  &[data-action="stop"],
  &[data-action="start"] {
    display: none;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  letter-spacing: 0;
}

.btn {
  svg {
    @include transition;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    svg {
      fill: color-contrast($value);
    }
  }
  .btn-outline-#{$color} {
    svg {
      fill: shade-color($value, 1);
    }
    &:hover {
      svg {
        fill: color-contrast($value);
      }
    }
  }
}

.w {
  &-150 {
    width: 150%;
  }
  &-200 {
    width: 200%;
  }
}

.footer {
  &__text {
    a {
      color: $white;
      &:hover {
        color: $secondary;
        svg {
          fill: $secondary;
        }
      }
      svg {
        fill: $white;
      }
    }
  }
}

.sidewidgets {
  ul {
    padding: 0 0 0 15px;
    text-transform: capitalize;
    margin: 0;
  }
}
.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0) !important;
  left: 0;
  bottom: 0;
}